import { combineReducers } from 'redux-immutablejs';
import { fromJS } from 'immutable';
import App from './modules/App/reducer';
import {
  APP_USER_LOGOUT,
  STORE_NAME as AppName,
} from './modules/App/constants';
import { STORE_NAME as ApplicationPresentationName } from './modules/ApplicationPresentation/constants';
import ApplicationPresentationReducer from './modules/ApplicationPresentation/reducer';
import { STORE_NAME as GlobalDialogName } from './modules/GlobalDialog/constants';
import NotificationsReducer from './modules/Notifications/reducer';
import { STORE_NAME as Notifications } from './modules/Notifications/constants';
import GlobalDialogReducer from './modules/GlobalDialog/reducer';
import LandingPage from './modules/LandingPage/reducer';
import { STORE_NAME as LandingPageName } from './modules/LandingPage/constants';
import Persistence from './modules/Persistence/reducer';
import {
  SERVER_LOAD_SUCCESS,
  STORE_NAME as PersistenceName,
} from './modules/Persistence/constants';
import AddressPicker from './modules/AddressPicker/reducer';
import {
  namesArray as addressPickerActionNames,
  STORE_NAME as AddressPickerName,
} from './modules/AddressPicker/constants';
import { getNamedStoreName as getAddressPickerStoreName } from 'redux/modules/AddressPicker/utils';
import EntryForm from './modules/EntryForm/reducer';
import { STORE_NAME as EntryFormName } from './modules/EntryForm/constants';
import EntryOrganic from 'redux/modules/EntryOrganic/reducer';
import { STORE_NAME as EntryOrganicName } from './modules/EntryOrganic/constants';
import YourDetails from 'redux/modules/YourDetails/reducer';
import { STORE_NAME as YourDetailsName } from 'redux/modules/YourDetails/constants';
import EnergyProductSelectionForm from './modules/Energy/ProductSelectionForm/reducer';
import { STORE_NAME as EnergyProductSelectionName } from './modules/Energy/ProductSelectionForm/constants';
import Cashback from './modules/Cashback/reducer';
import { STORE_NAME as CBCFormName } from './modules/Cashback/constants';
import Mobile from './modules/Mobile/reducer';
import { STORE_NAME as MobileName } from './modules/Mobile/constants';
import ServiceSelection from './modules/ServiceSelection/reducer';
import { STORE_NAME as ServiceSelectionName } from './modules/ServiceSelection/constants';
import PaymentForm from './modules/PaymentForm/reducer';
import { STORE_NAME as PaymentFormName } from './modules/PaymentForm/constants';
import FingerprintsWithChallenge from './modules/FingerprintsWithChallenge/reducer';
import { STORE_NAME as FingerprintsWithChallengeName } from './modules/FingerprintsWithChallenge/constants';
import EnergyQuote from './modules/Energy/Quote/reducer';
import { STORE_NAME as EnergyQuoteName } from './modules/Energy/Quote/constants';
import Broadband from './modules/Broadband/reducer';
import { STORE_NAME as BroadbandName } from './modules/Broadband/constants';
import GoldBenefit from './modules/GoldBenefit/reducer';
import { STORE_NAME as GoldBenefitName } from './modules/GoldBenefit/constants';
import OrderConfirmation from './modules/OrderSummary/reducer';
import { STORE_NAME as OrderConfirmationName } from './modules/OrderSummary/constants';
import Content from './modules/Content/reducer';
import { STORE_NAME as ContentName } from './modules/Content/constants';
import CBCChallenge from './modules/CBCChallenge/reducer';
import { STORE_NAME as CBCName } from './modules/CBCChallenge/constants';
import Eligibility from './modules/Eligibility/reducer';
import { STORE_NAME as EligibilityName } from './modules/Eligibility/constants';
import Bundles from './modules/Bundles/reducer';
import { STORE_NAME as BundlesName } from './modules/Bundles/constants';
import {
  PARTNER_LOGOUT,
  STORE_NAME as PartnerLoginName,
} from './modules/PartnerLogin/constants';
import { clearRouting } from './utils/localStorageHelpers';
import PartnerLogin, {
  createInitialPartnerState,
} from './modules/PartnerLogin/reducer';
import MobileContractRenewal from './modules/MobileContractRenewal/reducer';
import { STORE_NAME as MobileContractRenewalName } from './modules/MobileContractRenewal/constants';
import { homeInsuranceRenewalReducer as HomeInsuranceContractRenewal } from './modules/HomeInsuranceRenewal/reducer';
import { STORE_NAME as HomeInsuranceContractRenewalName } from './modules/HomeInsuranceRenewal/constants';
import SmartMeterInterestRegistration from './modules/SmartMeterInterest/reducer';
import { STORE_NAME as SmartMeterInterestRegistrationName } from './modules/SmartMeterInterest/constants';
import BoilerCover from './modules/BoilerCover/reducer';
import { STORE_NAME as BoilerCoverName } from './modules/BoilerCover/constants';
import HomeInsurance from './modules/HomeInsurance/reducer';
import { STORE_NAME as HomeInsuranceName } from './modules/HomeInsurance/constants';
import { mobileRenewalReducer as MobileRenewal } from './modules/MobileRenewal/reducer';
import { STORE_NAME as MobileRenewalName } from './modules/MobileRenewal/constants';
import { broadbandRenewalReducer as BroadbandRenewal } from './modules/BroadbandRenewal/reducer';
import { STORE_NAME as BroadbandRenewalName } from './modules/BroadbandRenewal/constants';
import {
  ACTION_TYPE as REMOTE_SESSION_ACTIONS,
  STORE_NAME as RemoteSessionName,
} from './modules/RemoteSession/v2/constants';
import RemoteSessionReducer from './modules/RemoteSession/v2/reducer';
import { STORE_NAME as FeatureFlagsName } from './modules/FeatureFlags/constants';
import FeatureFlagsReducer from './modules/FeatureFlags/reducer';
import { STORE_NAME as SalesLoginName } from './modules/SalesLogin/constants';
import SalesLoginReducer from './modules/SalesLogin/reducer';
import { STORE_NAME as Restore } from './modules/Restore/constants';
import RestoreReducer from './modules/Restore/reducer';
import { STORE_NAME as HomeAudit } from './modules/HomeAudit/constants';
import HomeAuditReducer from './modules/HomeAudit/reducer';
import { STORE_NAME as BillIncomeProtector } from './modules/BillIncomeProtector/constants';
import BillIncomeProtectorReducer from './modules/BillIncomeProtector/reducer';
import { STORE_NAME as Analytics } from 'redux/modules/Analytics/constants';
import AnalyticsReducer from 'redux/modules/Analytics/reducer';
import { STORE_NAME as Preselect } from 'app/redux/modules/Preselect/constants';
import PreselectReducer from 'redux/modules/Preselect/reducer';
import { STORE_NAME as LeadCapture } from 'app/redux/modules/LeadCapture/constants';
import LeadCaptureReducer from 'redux/modules/LeadCapture/reducer';

import { patcher } from './modules/RemoteSession/v2/differ';
import { merge } from './modules/RemoteSession/v2/merge';

// NOTE: version should be bumped up in case there is any store change that
// could break the application restoration from the snapshot.
export const REDUX_STORE_VERSION = 1;

const appReducer = combineReducers({
  [AppName]: App,
  [Analytics]: AnalyticsReducer,
  [ApplicationPresentationName]: ApplicationPresentationReducer,
  [LandingPageName]: LandingPage,
  [PersistenceName]: Persistence,
  [AddressPickerName]: AddressPicker(),
  ...addressPickerActionNames
    .map((name) => ({ [getAddressPickerStoreName(name)]: AddressPicker(name) }))
    .reduce((acc, val) => ({ ...acc, ...val }), {}),
  [EntryFormName]: EntryForm,
  [EntryOrganicName]: EntryOrganic,
  [YourDetailsName]: YourDetails,
  [EnergyProductSelectionName]: EnergyProductSelectionForm,
  [MobileName]: Mobile,
  [ServiceSelectionName]: ServiceSelection,
  [GoldBenefitName]: GoldBenefit,
  [PaymentFormName]: PaymentForm,
  [FingerprintsWithChallengeName]: FingerprintsWithChallenge,
  [EnergyQuoteName]: EnergyQuote,
  [BroadbandName]: Broadband,
  [OrderConfirmationName]: OrderConfirmation,
  [ContentName]: Content,
  [CBCName]: CBCChallenge,
  [EligibilityName]: Eligibility,
  [CBCFormName]: Cashback,
  [PartnerLoginName]: PartnerLogin,
  [BundlesName]: Bundles,
  [MobileContractRenewalName]: MobileContractRenewal,
  [HomeInsuranceContractRenewalName]: HomeInsuranceContractRenewal,
  [MobileRenewalName]: MobileRenewal,
  [BroadbandRenewalName]: BroadbandRenewal,
  [SmartMeterInterestRegistrationName]: SmartMeterInterestRegistration,
  [BoilerCoverName]: BoilerCover,
  [HomeInsuranceName]: HomeInsurance,
  [RemoteSessionName]: RemoteSessionReducer,
  [FeatureFlagsName]: FeatureFlagsReducer,
  [SalesLoginName]: SalesLoginReducer,
  [GlobalDialogName]: GlobalDialogReducer,
  [Notifications]: NotificationsReducer,
  [Restore]: RestoreReducer,
  [HomeAudit]: HomeAuditReducer,
  [BillIncomeProtector]: BillIncomeProtectorReducer,
  [Preselect]: PreselectReducer,
  [LeadCapture]: LeadCaptureReducer,
});

const addToObj = (obj, path, data) => {
  if (path.length === 0) return data;
  const [head, ...tail] = path;
  return {
    ...obj,
    [head]: addToObj(obj[head] || {}, tail, data),
  };
};
export function getPreservedStateObj(state, path) {
  const data = state.getIn(path);
  const result = addToObj({}, path, data.toJS ? data.toJS() : data);
  return result;
}
const rootReducer = (state, action) => {
  if (action.type === APP_USER_LOGOUT) {
    state = fromJS({
      [PartnerLoginName]: createInitialPartnerState({
        hostedPartner: state.toJS()[PartnerLoginName].hostedPartner,
      }),
    });
    clearRouting();
  } else if (action.type === PARTNER_LOGOUT) {
    state = state.mergeDeep({
      [PartnerLoginName]: createInitialPartnerState(
        state.toJS()[PartnerLoginName]
      ),
      ...action.preservedState
        .map((path) => getPreservedStateObj(state, path))
        .reduce((acc, v) => {
          return {
            ...acc,
            ...v,
          };
        }, {}),
    });
    clearRouting();
  } else if (action.type === SERVER_LOAD_SUCCESS) {
    state = state.mergeDeep(action.payload);
  } else if (action.type == REMOTE_SESSION_ACTIONS.STATE_UPDATE_RECEIVED) {
    if (action.diff) {
      const patchedState = patcher(state, fromJS(action.diff));
      state = merge(state, patchedState);
    } else if (action.state) {
      state = merge(state, fromJS(action.state));
    }
  }

  const newState = appReducer(state, action);
  return newState;
};

export default rootReducer;
